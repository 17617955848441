<template>
  <div class="page">
    <div class="wrapper">
      <div class="content">
        <!-- 顶部视频或图片 -->
        <div class="videoOrImagesBox">
          <video
            controls="controls"
            v-if="detail.videoUrl != null"
            height="100%"
            width="100%"
            :src="detail.videoUrl"
          ></video>
          <div class="swiper-w" v-else>
            <van-swipe class="swiper" indicator-dots="true" interval="2000">
              <van-swipe-item
                v-for="(item, index) in detail.picUrl"
                :key="index"
              >
                <van-image :src="item" fit="scale-down" />
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
        <!-- 在线学习信息 -->
        <div class="studyDetailBox">
          <div class="title">{{ detail.topicTitle || "" }}</div>
          <div class="lineBox">
            <div class="eyeBox">
              <van-image :src="require('./img/eye.png')" />
            </div>
            <div class="readNnm">
              {{ detail.readCount || "0" }} 次阅读
              <!-- ｜ {{ detail.praiseCount || "0" }} 次点赞 -->
            </div>
            <div class="time">{{ detail.createTime }}</div>
          </div>
          <div class="briefIntroductionIBox">
            <div v-if="!detail.topicContent" class="text-empty">暂无文本</div>
            <div v-else class="text" v-html="detail.topicContent"></div>
            <!-- <mp-html :content="detail.topicContent" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTopicInfoByIdURL, getCountUrl } from "./api";
import { Image as VanImage } from "vant";
import { Swipe as VanSwipe, SwipeItem as VanSwipeItem } from "vant";

export default {
  name: "onLineStudyDetail",
  components: {
    VanImage,
    "van-swipe": VanSwipe,
    "van-swipe-item": VanSwipeItem,
  },
  data() {
    return {
      id: "", // 记录详情页id
      detail: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    // 获取详情
    getDetail() {
      this.$axios
        .get(getTopicInfoByIdURL, {
          params: {
            id: this.id,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.detail = res.data;
            if (res.data.picUrl != null) {
              this.detail.picUrl = res.data.picUrl.split(",");
            }
            this.getCount();
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        });
    },
    getCount() {
      this.$axios.get(getCountUrl, { params: { id: this.id } });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  background: #017eff;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);

  /* 兼容 iOS >= 11.2 */
  .wrapper {
    background: #ffffff;
    min-height: 100vh;
    box-sizing: border-box;

    > .spaceLine {
      height: 32px;
      width: 100%;
      background: #f5f5f5;
    }

    > .content {
      box-sizing: border-box;
      width: 100%;
      overflow-x: hidden;
      font-size: 32px;
      color: #333333;
      word-wrap: break-word;

      > .videoOrImagesBox {
        width: 100%;
        background: #000;
        height: 514px;

        // background: #4CD964;
        .swiper-w {
          width: 100%;
          height: 514px;
        }

        .swiper {
          height: 514px;
        }

        swiper-item > image {
          width: 100%;
          height: 514px;
        }
      }

      > .studyDetailBox {
        box-sizing: border-box;
        padding: 36px 32px 36px 28px;
        .title {
          font-size: 44px;
          color: #1a1c34;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .lineBox {
          width: 100%;
          font-size: 28px;
          color: #a3a3a3;
          .eyeBox {
            display: inline-block;
            vertical-align: middle;

            > image {
              width: 28px;
              height: 28px;
            }
          }
          .readNnm {
            margin-left: 6px;
            display: inline-block;
          }
          .time {
            margin-left: 84px;
            display: inline-block;
          }
        }

        .briefIntroductionIBox {
          width: 100%;
          overflow-x: hidden;
          box-sizing: border-box;
          // margin-bottom: 80px;
          font-size: 32px;
          line-height: 1.5;
          color: #333333;
          word-wrap: break-word;
          > .text-empty {
            height: 96px;
            line-height: 96px;
            background: #eeeeee;
            text-align: center;
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          ::v-deep img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
